





















































































































































































































































































































































































































































































































































































































































import Vue, { VueConstructor } from 'vue'
import { ValidationObserver, extend } from 'vee-validate'
import Category from '../../../../store/models/Category'
import { mapGetters } from 'vuex'
interface ComponentWithValidator extends Vue {
  isSFD: boolean
  successAlertMessage: string
  dangerAlertMessage: string
  loading: boolean
  createModal: boolean
  selectededCategorie: any
  supModal: boolean
  createName: string
  createCode: string
  name: string
  code: string
  showModal: boolean
  search: string
  hideSearchInput: boolean
  $refs: {
    validator: InstanceType<typeof ValidationObserver>
  }
}
export default (Vue as VueConstructor<ComponentWithValidator>).extend({
  data() {
    return {
      isSFD: false,
      successAlertMessage: '',
      dangerAlertMessage: '',
      loading: false,
      createModal: false,
      selectededCategorie: {
        type: Object
      },
      supModal: false,
      createName: '',
      createCode: '',
      name: '',
      code: '',
      showModal: false,
      search: '',
      hideSearchInput: true
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    userIsAuthorized(): boolean {
      if (
        (this.user.role == 'fnm:admin' || this.user.role == 'fnm:superadmin') &&
        !this.isSFD
      )
        return true
      return false
    },
    categories(): Array<Category> {
      if (this.search != '')
        return Category.query()
          .where((categorie: Category) => {
            return (
              categorie.name.includes(this.search) ||
              categorie.code.includes(this.search)
            )
          })
          .orderBy('name')
          .get()
      console.log()
      return Category.all()
    }
  },
  methods: {
    createCategories(): void {
      this.dangerAlertMessage = ''
      this.successAlertMessage = ''
      this.$refs.validator.validate().then(async (isValid: boolean) => {
        if (isValid) {
          this.loading = true
          Category.api()
            .create({
              name: this.createName,
              code: this.createCode
            })
            .then(() => {
              this.closeCreateModal()
              this.successAlertMessage = `La catégorie ${this.createName} a été crée avec succès`
              ;(this.createName = ''), (this.createCode = '')
            })
          this.loading = false
        }
      })
    },
    modifCat(id: string): void {
      this.dangerAlertMessage = ''
      this.successAlertMessage = ''
      Category.api()
        .updateOne(id, {
          name: this.name,
          code: this.code
        })
        .then(() => {
          this.successAlertMessage = `La catégorie ${this.name} a été mise à jour avec succès`
          this.close()
        })
    },
    supCat(): void {
      this.dangerAlertMessage = ''
      this.successAlertMessage = ''
      Category.api()
        .remove(this.selectededCategorie.id)
        .then((response: any) => {
          if (response.response.status == 200) {
            console.log(response.data, 'this is response')
            Category.delete(this.selectededCategorie.id)
            this.successAlertMessage = `La catégorie ${this.name} a été supprimé avec succès`
          }
        })
        .catch((error: any) => {
          if (error.response.data.error == 'existing product') {
            this.dangerAlertMessage =
              "Impossible de supprimer cette catégorie, elle a été utilisé dans la création d'un produit"
          }
        })
      this.closeSup()
    },
    showEditModal(data: any): void {
      console.log(data)
      this.name = data.name
      this.code = data.code
      this.showModal = !this.showModal
    },

    showSupModal(categorie: Category): void {
      this.selectededCategorie = categorie
      this.supModal = true
    },

    close(): void {
      this.showModal = false
    },
    closeCreateModal(): void {
      this.createModal = false
      this.loading = false
    },
    closeSup(): void {
      this.supModal = false
    }
  },

  created(): void {
    Category.api().fetchAll()
    extend('uniqueCategoryCode', {
      message: () => 'Ce code est déjà utilisé',
      validate: value => {
        if (
          Category.query()
            .where('code', value)
            .exists()
        )
          return false
        return true
      }
    })
    extend('uniqueCategoryName', {
      message: () => 'Ce nom est déjà utilisé',
      validate: value => {
        if (
          Category.query()
            .where('name', value)
            .exists()
        )
          return false
        return true
      }
    })
  }
})
